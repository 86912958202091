<template>
  <div class="carrot-layer-bg">
    <div class="carrot-layer w-700px h-450px">
      <div>
        <p class="title">강의실 대여요금</p>
        <span class="btn-close" @click="pop.onClose()"
          ><i class="fas fa-times"></i
        ></span>
      </div>
      <div class="mt-10 tbl-light">
        <div class="w-100per">
          <!--
                    <button @click="pop.selected=0" class="btn-default float-left mb-5 mr-5">글로벌센터 이용요금</button>
                    <button @click="pop.selected=1" class="btn-default float-left mb-5 mr-5">한남센터 이용요금</button>
                    -->
          <button
            @click="pop.selected = 2"
            class="btn-default float-left mb-5 mr-5"
          >
            한남캠퍼스 SITE1
          </button>
          <button
            @click="pop.selected = 3"
            class="btn-default float-left mb-5 mr-5"
          >
            한남캠퍼스 SITE2
          </button>
          <!-- <button
            @click="pop.selected = 4"
            class="btn-default float-left mb-5 mr-5"
          >
            강남 Coactive-Center
          </button> -->
          <div class="clear"></div>
        </div>
        <table v-if="pop.selected == 0" class="tbl-light-view">
          <colgroup>
            <col width="120" />
            <col width="134" />
            <col width="134" />
            <col width="134" />
            <col width="135" />
          </colgroup>
          <tbody>
            <tr>
              <th>가능 시간</th>
              <td colspan="4" class="txt-center">
                월요일 ~ 금요일 (8:00 ~ 21:00)<br />토요일/공휴일 (8:00 ~ 18:00)
              </td>
            </tr>
            <tr>
              <th>인원</th>
              <td class="txt-center">6명~9명<br />(小강의장)</td>
              <td class="txt-center">10명~12명<br />(中강의장)</td>
              <td class="txt-center">20명~25명<br />(大강의장)</td>
              <td class="txt-center">25명~36명<br />(大강의장)</td>
            </tr>
            <tr>
              <th>3시간 기준</th>
              <td class="txt-center">150,000원</td>
              <td class="txt-center">180,000원</td>
              <td class="txt-center">250,000원</td>
              <td class="txt-center">300,000원</td>
            </tr>
            <tr>
              <th>종일<br />(8시간 기준)</th>
              <td class="txt-center">300,000원</td>
              <td class="txt-center">400,000원</td>
              <td class="txt-center">500,000원</td>
              <td class="txt-center">600,000원</td>
            </tr>
            <tr>
              <th>Option</th>
              <td colspan="4" class="txt-center">
                노트북 : 5,000원(3시간) / 10,000원(8시간)<br />빔 :
                5,000원(3시간) / 10,000원(8시간)
              </td>
            </tr>
            <tr>
              <th>주차료</th>
              <td colspan="4" class="txt-center">
                10분당 1,000원<br />차량 1대 종일 30,000원
              </td>
            </tr>
          </tbody>
        </table>
        <table v-if="pop.selected == 1" class="tbl-light-view">
          <colgroup>
            <col width="120" />
            <col width="179" />
            <col width="179" />
            <col width="179" />
          </colgroup>
          <tbody>
            <tr>
              <th>가능 시간</th>
              <td colspan="3" class="txt-center">
                평일 : 09:00 ~ 18:00<br />&nbsp;
              </td>
            </tr>
            <tr>
              <th>인원</th>
              <td class="txt-center">6명~9명<br />(小강의장)</td>
              <td class="txt-center">10명~12명<br />(中강의장)</td>
              <td class="txt-center">40명~50명<br />(전체 대여, 강의장5개)</td>
            </tr>
            <tr>
              <th>3시간 기준</th>
              <td class="txt-center">100,000원</td>
              <td class="txt-center">120,000원</td>
              <td class="txt-center">360,000원</td>
            </tr>
            <tr>
              <th>종일<br />(8시간 기준)</th>
              <td class="txt-center">270,000원</td>
              <td class="txt-center">300,000원</td>
              <td class="txt-center">900,000원</td>
            </tr>
            <tr>
              <th>Option</th>
              <td colspan="3" class="txt-center">
                노트북 : 5,000원(3시간) / 10,000원(9시간)<br />빔 :
                5,000원(3시간) / 10,000원(9시간)
              </td>
            </tr>
            <tr>
              <th>주차료</th>
              <td colspan="3" class="txt-center">
                센터 내 주차 불가<br />(한강진역 공영주차장 이용 가능 : 5분당
                250원 / 1시간 3,000원)
              </td>
            </tr>
          </tbody>
        </table>
        <table v-if="pop.selected == 2" class="tbl-light-view">
          <colgroup>
            <col width="120" />
            <col width="168" />
            <col width="168" />
          </colgroup>
          <tbody>
            <tr>
              <th>강의장명</th>
              <td colspan="4" class="txt-center">한남캠퍼스 SITE1</td>
            </tr>
            <tr>
              <th>인원</th>
              <td class="txt-center">대 강의장<br />(25인~36인)</td>
              <td class="txt-center">중 강의장<br />(10인~15인)</td>
            </tr>
            <tr>
              <th>1일 3시간 기준</th>
              <td class="txt-center">300,000원</td>
              <td class="txt-center">180,000원</td>
            </tr>
            <tr>
              <th>1일 9시간 기준<br />(09:00 ~ 18:00)</th>
              <td class="txt-center">600,000원</td>
              <td class="txt-center">350,000원</td>
            </tr>
            <tr>
              <th>비고</th>
              <td colspan="4" class="txt-center">음료 (커피, Tea 등) 제공</td>
            </tr>
            <tr>
              <th>참고사항</th>
              <td colspan="4" class="txt-left">
                1.본 견적은 VAT 별도이며, 견적일로부터 30일간 유효합니다.<br />
                2.주차 무료 지원은 불가합니다. (한강진 역 공영주차장 → 1시간
                4,800원)<br />
                3.견적은 1일 9시간 기준이며, 9시간이하의 이용비는 협의를 통해
                진행합니다.
              </td>
            </tr>
          </tbody>
        </table>
        <table v-if="pop.selected == 3" class="tbl-light-view">
          <colgroup>
            <col width="120" />
            <col width="112" />
            <col width="112" />
            <col width="112" />
          </colgroup>
          <tbody>
            <tr>
              <th>강의장명</th>
              <td colspan="4" class="txt-center">한남캠퍼스 SITE2</td>
            </tr>
            <tr>
              <th>인원</th>
              <td class="txt-center">전체 대여<br />(강의장 5개, 50인)</td>
              <td class="txt-center">중 강의장<br />(8인~12인)</td>
              <td class="txt-center">소 강의장<br />(2인~4인)</td>
            </tr>
            <tr>
              <th>1일 3시간 기준</th>
              <td class="txt-center">500,000원</td>
              <td class="txt-center">180,000원</td>
              <td class="txt-center">130,000원</td>
            </tr>
            <tr>
              <th>1일 9시간 기준<br />(09:00 ~ 18:00)</th>
              <td class="txt-center">1000,000원</td>
              <td class="txt-center">350,000원</td>
              <td class="txt-center">250,000원</td>
            </tr>
            <tr>
              <th>비고</th>
              <td colspan="4" class="txt-center">음료 (커피, Tea 등) 제공</td>
            </tr>
            <tr>
              <th>참고사항</th>
              <td colspan="4" class="txt-left">
                1.본 견적은 VAT 별도이며, 견적일로부터 30일간 유효합니다.<br />
                2.주차 무료 지원은 불가합니다. (한강진 역 공영주차장 → 1시간
                4,800원)<br />
                3.견적은 1일 9시간 기준이며, 9시간이하의 이용비는 협의를 통해
                진행합니다.
              </td>
            </tr>
          </tbody>
        </table>
        <table v-if="pop.selected == 4" class="tbl-light-view">
          <colgroup>
            <col width="120" />
            <col width="168" />
            <col width="168" />
          </colgroup>
          <tbody>
            <tr>
              <th>강의장명</th>
              <td colspan="4" class="txt-center">강남 Coactive-Center</td>
            </tr>
            <tr>
              <th>인원</th>
              <td class="txt-center">대 강의장<br />(25인~36인)</td>
              <td class="txt-center">중 강의장<br />(10인~15인)</td>
            </tr>
            <tr>
              <th>1일 3시간 기준</th>
              <td class="txt-center">300,000원</td>
              <td class="txt-center">180,000원</td>
            </tr>
            <tr>
              <th>1일 9시간 기준<br />(09:00 ~ 18:00)</th>
              <td class="txt-center">600,000원</td>
              <td class="txt-center">350,000원</td>
            </tr>
            <tr>
              <th>비고</th>
              <td colspan="4" class="txt-center">음료 (커피, Tea 등) 제공</td>
            </tr>
            <tr>
              <th>참고사항</th>
              <td colspan="4" class="txt-left">
                1.본 견적은 VAT 별도이며, 견적일로부터 30일간 유효합니다.<br />
                2.주차 지원은 불가합니다.<br />
                3.견적은 1일 9시간 기준이며, 9시간이하의 이용비는 협의를 통해
                진행합니다.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "ClassroomRentalcostPopup",
  props: {
    idx: {
      type: [Number, String],
      default: 0,
    },
  },
  setup(props, { emit }) {
    const pop = reactive({
      selected: 2,

      onClose: () => {
        emit("close");
      },
    });

    return { pop };
  },
};
</script>
<style lang="scss" scoped></style>
