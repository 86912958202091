<template>
  <div class="carrot-layer-bg">
    <div class="carrot-layer w-900px h-600px">
      <div>
        <p class="title">강의실 지도</p>
        <span class="btn-close" @click="pop.onClose()"
          ><i class="fas fa-times"></i
        ></span>
      </div>
      <div class="mt-10 h-500px">
        <div class="w-100per">
          <button
            v-for="(irow, i) in pop.maps"
            :key="i"
            @click="pop.selected = i"
            class="btn-default float-left mb-5 mr-5"
          >
            {{ irow.name }}
          </button>
          <div class="clear"></div>
        </div>
        <div v-for="(irow, i) in pop.maps" :key="i" class="mt-10 txt-center">
          <img
            v-if="pop.selected == i"
            :src="pop.maps[i].src"
            class="h-400px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  name: "ClassroomSitePopup",
  props: {
    idx: {
      type: [Number, String],
      default: 0,
    },
  },
  setup(props, { emit }) {
    const pop = reactive({
      maps: [
        {
          name: "한남캠퍼스 Site1 1F",
          src: require("@/assets/img/map/han_site1_1f.jpg"),
        },
        {
          name: "한남캠퍼스 Site1 2F",
          src: require("@/assets/img/map/han_site1_2f.jpg"),
        },
        {
          name: "한남캠퍼스 Site1 4F",
          src: require("@/assets/img/map/han_site1_4f.jpg"),
        },
        {
          name: "한남캠퍼스 Site1 5F",
          src: require("@/assets/img/map/han_site1_5f.jpg"),
        },
        {
          name: "한남캠퍼스 Site1 6F",
          src: require("@/assets/img/map/han_site1_6f.jpg"),
        },
        {
          name: "한남캠퍼스 Site2 1~2F",
          src: require("@/assets/img/map/han_site2_12f.jpg"),
        },
        {
          name: "강남 캠퍼스 Solutions 3F",
          src: require("@/assets/img/map/map_gangnam_3f.jpg"),
        },
        {
          name: "강남 캠퍼스 Solutions 6F",
          src: require("@/assets/img/map/map_gangnam_6f.jpg"),
        },
      ],

      selected: 0,

      onClose: () => {
        emit("close");
      },
    });

    return { pop };
  },
};
</script>
<style lang="scss" scoped></style>
